import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import CaseStudyHeader from "../../../components/pages/work/case-studies/CaseStudyHeader"
import CaseStudyConsultation from "../../../components/pages/work/case-studies/CaseStudyConsultation"
import CaseStudyUserStories from "../../../components/pages/work/case-studies/CaseStudyUserStories"
import CaseStudyWireframes from "../../../components/pages/work/case-studies/CaseStudyWireframes"
import CaseStudyIterations from "../../../components/pages/work/case-studies/CaseStudyIterations"
import CaseStudyTechnologies from "../../../components/pages/work/case-studies/CaseStudyTechnologies"
import CaseStudyResults from "../../../components/pages/work/case-studies/CaseStudyResults"
import Contact from "../../../components/pages/index/Contact"
import CaseStudyChallenges from "../../../components/pages/work/case-studies/CaseStudyChallenges"

const FoodelMobilePage = ({ pageContext, location }) => {
  const consultationDetails = {
    company: "Foodel",
    location: "Jacksonville, FL",
    industry: "Food Delivery",
    developmentType: "Mobile App",
    scope: "Design and Full Stack Development",
    businessObjectives:
      "To become the go to healthy meal subscription delivery service in Jacksonville and generate $100K in sales within their first year of business. This will ideally require 20,000 downloads.",
    desiredOutcomes:
      "To have an easy to use mobile application where customers can create and manage their meal subscription. This includes adding and removing meals as well as being able to rate meals they've tried. With an easy to use app they should be able to convert 5% of users.",
    targetMarket: "Busy Professionals",
    competition: "Fresh n' Lean",
    competitiveAdvantage:
      "Food is delivered hot and fresh vs. prepackaged and requiring reheating.",
    timeFrame: "6 Months",
  }

  const userStories = {
    mustHaves: [
      "User accounts required with ability to manage profile in app",
      "Ability to manage subscription in app",
      "Multiple login options including Facebook and Google",
      "Multiple menu selections including breakfast, lunch, dinner, dessert, snacks and drinks",
      "Each menu will have multiple meal choices with images and user ratings",
      "Each meal to have its own description page with detailed ratings and ability to rate",
      "Users should be able to add and remove items from their shopping cart easily",
      "Meal images should be prominent throughout app",
      "Need an admin portal for user statistics and managing meal orders",
      "Users must also be able to view and manage orders",
      "A user support link that links to Zendesk",
      "Show sold out notice when a meal is out of stock",
    ],
    niceToHaves: [
      "Chat bot integration to provide customer support",
      "Order tracking integration with Google maps",
      "Ability to share meal choices on social media",
      "Allow users to leave video reviews of their favorite meals",
      "Add meal statistical data in the admin panel",
      "Content management system integration for managing meals",
    ],
    maybeLater: [
      "Add an AI bot to support with frequently asked questions",
      "Delivery driver login to show their delivery route",
      "Ability to send push notifications for newest meal additions",
      "Add a free newsletter subscription option",
      "A coupon management system",
      "Refer a friend link",
    ],
  }

  const iterations = {
    iteration1: {
      stories: [
        "Design and development environment setup",
        "Database selection and setup",
        "Asset collection and review",
        "Wireframe review",
      ],
      weeks: "Week 1",
    },
    iteration2: {
      stories: [
        "Landing Screen Design & Development",
        "Login Screen Design & Development",
        "Register Screen Design & Development",
        "Database configuration for user registration & login",
      ],
      weeks: "Week 2-3",
    },
    iteration3: {
      stories: [
        "Menu Selection Design & Development",
        "Database configuration for menu selection star rating system",
      ],
      weeks: "Week 4",
    },
    iteration4: {
      stories: [
        "Breakfast, lunch, dinner, dessert, snacks and drinks menus design and development with client's assets",
        "Database configuration for individual meal star rating system",
      ],
      weeks: "Week 5-6",
    },
    iteration5: {
      stories: [
        "Individual meal pages design and development with provided assets",
        "Implement star rating system using previously developed database configuration",
        "Create front end design and backend development for review system on details page",
      ],
      weeks: "Week 7-8",
    },
    iteration6: {
      stories: [
        "Design and develop order summary (shopping cart) screen",
        "Develop a local shopping cart state and enable 'Add To Cart' buttons on menu and details screens",
        "Connect order summary page and shopping cart state",
        "Develop financial calculations for order summary page",
      ],
      weeks: "Week 9-10",
    },
    iteration7: {
      stories: [
        "Design and develop 'payment method selection' screen with credit card slider",
        "Integrate credit card processing through financial institution using API",
        "Add Paypal as a payment method for faster checkout",
        "Develop backend tie-in to record order details per client",
      ],
      weeks: "Week 11-12",
    },
    iteration8: {
      stories: [
        "Design and develop post-order summary screen as a form of receipt and tracking",
        "Add 'Repeat Order' button for easy re-ordering",
      ],
      weeks: "Week 13",
    },
    iteration9: {
      stories: [
        "Design and develop a slide out menu attached to the burger-menu-selection icon",
        "Link the 'Menu' icon to the main 'Menu Selection' screen",
      ],
      weeks: "Week 14",
    },
    iteration10: {
      stories: [
        "Integrate Zendesk into app for client support ticketing system",
      ],
      weeks: "Week 15-16",
    },
    iteration11: {
      stories: [
        "Design and develop 'Profile' screen allowing users to edit basics like name, email, phone, address, and password.",
      ],
      weeks: "Week 17",
    },
    iteration12: {
      stories: [
        "Design and develop 'Settings' screen allowing users to edit their preferences like payment methods, not showing out of stock meals, and selecting dark mode",
      ],
      weeks: "Week 18-19",
    },
    iteration13: {
      stories: [
        "Design and develop the 'Subscription' screen where users can sign up and modify their meal subscription",
      ],
      weeks: "Week 20-21",
    },
    iteration14: {
      stories: [
        "Design and develop the 'My Orders' screen where users can see past orders",
      ],
      weeks: "Week 22",
    },
    iteration15: {
      stories: [
        "Release",
        "Emergency support standby as website is tested with live traffic",
      ],
      weeks: "Week 23",
    },
  }

  const technologies = {
    fullStack: true,
    icons: [
      "HTML5",
      "CSS3",
      "Sass",
      "JavaScript",
      "React Native",
      "Redux",
      "Jest",
      "Node",
      "Mongodb",
      "Git",
    ],
  }

  const challenges = {
    challenge1: {
      challenge:
        "Foodel had a large list of feature requests with a limiting time frame.",
      win: (
        <>
          <p>
            Foodel had a large list of agile stories (feature requests) that
            required nearly double the alloted time. This is quite common
            amongst our clients as we encourage them to think big.
          </p>
          <p>
            Using an agile approach we prioritized, sized and iterated their
            stories to provide them with an app release that would help them
            achieve their desired business outcomes.
          </p>
          <p className="mb-0">
            This allowed them to get up and running in their 6 month time frame
            giving them the ability to build their business, generate profits
            and plan for the next app release with extended features.
          </p>
        </>
      ),
    },
    challenge2: {
      challenge:
        "Foodel needed a robust support ticketing system to integrate into their app.",
      win: (
        <>
          <p>
            As a food delivery service, Foodel wanted a robust ticketing system,
            ideally with an integrated chat system for support. After some
            discussion, we settled on the Zendesk platform.
          </p>
          <p>
            Unfortunately there was no native integration for Zendesk within
            React Native so our development team rolled up their sleeves and
            built custom integrations with Zendesk's mobile SDK.
          </p>
          <p className="mb-0">
            Being weary of time and budget, the chat system didn't make it into
            the first app release but is slated for the next release.
          </p>
        </>
      ),
    },
    challenge3: {
      challenge:
        "The Foodel team provided digital assets that weren't compatible with high resolution mobile screens",
      win: (
        <>
          <p>
            As our design team was working with the images provided by the
            Foodel team, it became clear that they weren't shot with the right
            resolution and lighting for display on mobile screens.
          </p>
          <p className="mb-0">
            We worked with the Foodel team to re-shoot the images with better
            lighting, layout, and resolution for optimal compatibility with
            their mobile application.
          </p>
        </>
      ),
    },
    challenge4: {
      challenge:
        "Facebook released a major React Native update that included security improvements and code rework.",
      win: (
        <>
          <p>
            Our team puts major focus on app security and after reviewing the
            changes in the new React Native release we concluded that the Foodel
            app needed this major update before its release.
          </p>
          <p className="mb-0">
            We hired a freelance React Native Developer to focus on the code
            upgrade while our team continued developing the app. Doing this
            allowed us to stick to the release schedule while making the app
            more secure.
          </p>
        </>
      ),
    },
    challenge5: {
      challenge:
        "Midway through the project the app started to drain our test phone's battery.",
      win: (
        <>
          <p>
            In month 4 of the project we noticed the application started to
            drain our test phone battery at an alarming rate. These types of
            issues have the ability to derail projects so we took this challenge
            head on.
          </p>
          <p className="mb-0">
            Our development team combed through thousands of lines of code for 2
            days straight and eventually found the issue in a supporting
            package. We also split the workload so the iteration timeline
            remained on track.
          </p>
        </>
      ),
    },
  }

  const caseStudyData = {
    numBoxes: {
      developers: "2",
      hourly: "105",
      time: "6",
    },
  }

  const resultsImageNames = {
    results1: "Landing Screen",
    results2: "Register Screen",
    results3: "Login Screen",
    results4: "Menu Selection Screen",
    results5: "Lunch Menu Screen",
    results6: "Menu Slide-Out",
    results7: "Meal Details Screen",
    results8: "Meal Details Screen Scrolled",
    results9: "Order Summary Screen",
    results10: "Credit Card Screen",
    results11: "Order Complete Screen",
    results12: "Order Details Screen",
  }

  const resultsImageModalSize = "md"

  return (
    <StaticQuery
      query={graphql`
        {
          hero: file(
            relativePath: { eq: "work/mobile/foodel/foodel-hero.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          deviceDisplay: file(
            relativePath: { eq: "work/mobile/foodel/foodel-devices.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe1: file(
            relativePath: {
              eq: "work/mobile/foodel/wireframes/landing_register.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe2: file(
            relativePath: { eq: "work/mobile/foodel/wireframes/login_menu.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe3: file(
            relativePath: {
              eq: "work/mobile/foodel/wireframes/meal_menu_slide_out.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe4: file(
            relativePath: {
              eq: "work/mobile/foodel/wireframes/meal_details.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe5: file(
            relativePath: {
              eq: "work/mobile/foodel/wireframes/order_summary_credit_card.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe6: file(
            relativePath: {
              eq: "work/mobile/foodel/wireframes/order_complete_details.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          results1: file(
            relativePath: { eq: "work/mobile/foodel/1-landing-page.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 450, layout: CONSTRAINED)
            }
          }
          results2: file(
            relativePath: { eq: "work/mobile/foodel/2-register-page.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 450, layout: CONSTRAINED)
            }
          }
          results3: file(
            relativePath: { eq: "work/mobile/foodel/3-login-page.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 450, layout: CONSTRAINED)
            }
          }
          results4: file(
            relativePath: { eq: "work/mobile/foodel/4-menu-page.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 450, layout: CONSTRAINED)
            }
          }
          results5: file(
            relativePath: { eq: "work/mobile/foodel/5-lunch-menu-page.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 450, layout: CONSTRAINED)
            }
          }
          results6: file(
            relativePath: { eq: "work/mobile/foodel/6-side-menu.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 450, layout: CONSTRAINED)
            }
          }
          results7: file(
            relativePath: { eq: "work/mobile/foodel/7-1-meal-details-page.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 450, layout: CONSTRAINED)
            }
          }
          results8: file(
            relativePath: { eq: "work/mobile/foodel/7-2-meal-details-page.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 450, layout: CONSTRAINED)
            }
          }
          results9: file(
            relativePath: { eq: "work/mobile/foodel/8-order-summary-page.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 450, layout: CONSTRAINED)
            }
          }
          results10: file(
            relativePath: { eq: "work/mobile/foodel/9-credit-card-page.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 450, layout: CONSTRAINED)
            }
          }
          results11: file(
            relativePath: {
              eq: "work/mobile/foodel/10-order-complete-page.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 450, layout: CONSTRAINED)
            }
          }
          results12: file(
            relativePath: { eq: "work/mobile/foodel/11-order-details-page.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 450, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={(data) => (
        <Layout>
          <Seo
            title="Foodel Mobile App Case Study"
            keywords={[`foodel mobile app case study`]}
            description="Foodel is a mobile application that offers a healthy meal delivery subscription service. It required full stack development services deployed to both Android and IOS."
          />
          <CaseStudyHeader
            image={data.hero.childImageSharp.gatsbyImageData}
            title="Mobile Application Case study"
            subTitle="Foodel"
            pageContext={pageContext}
            location={location}
            crumbLabel="Foodel"
          />
          <main>
            <CaseStudyConsultation
              consultationDetails={consultationDetails}
              deviceImage={data.deviceDisplay}
            />
            <CaseStudyUserStories
              userStories={userStories}
              companyName={consultationDetails.company}
            />
            <CaseStudyWireframes
              wireframeImages={data}
              companyName={consultationDetails.company}
            />
            <CaseStudyIterations
              iterationData={iterations}
              companyName={consultationDetails.company}
            />
            <CaseStudyTechnologies
              technologies={technologies}
              companyName={consultationDetails.company}
              developmentType={consultationDetails.developmentType}
            />
            <CaseStudyChallenges
              challenges={challenges}
              companyName={consultationDetails.company}
            />
            <CaseStudyResults
              caseStudyData={caseStudyData}
              resultsImages={data}
              resultsImageNames={resultsImageNames}
              resultsImageModalSize={resultsImageModalSize}
              companyName={consultationDetails.company}
              developmentType={consultationDetails.developmentType}
            />
            <Contact
              heading={
                <>
                  Let's build your{" "}
                  <span className="text-primary">
                    {consultationDetails.developmentType}
                  </span>{" "}
                  Together
                </>
              }
            />
          </main>
        </Layout>
      )}
    />
  )
}

export default FoodelMobilePage
